// /* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SEO from '../components/seo';
import theme from '../themes/dcomTheme';
import { getUrlParameter, getPhoneMylife } from '../helpers/helpers';
import myLifeData from '../data/my-life';
import Layout from '../components/layout';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/FooterSection';
import ATEForm from '../components/FormSection/ATEForm';
import Disclaimer from '../components/Disclaimer/Disclaimer';
import TwoColumns from '../components/TwoColumns/TwoColumns';
import HeroSection from '../components/HeroSection/HeroSection';
import InfoSection from '../components/InfoSection/InfoSection';
import HelpSection from '../components/HelpSection/HelpSection';
import fbImg from '../images/mylife-social.jpg';

const {
  credibilityLogos,
  logos,
  heroSection,
  infoSection,
  helpSection,
  form,
  disclaimer,
  footer,
} = myLifeData;

let { headerPhone } = myLifeData;

const pid = getUrlParameter('pid');

const realPhone = getPhoneMylife(pid);

headerPhone = Object.assign(headerPhone, realPhone);

const seo = {
  title: 'Free Service Helps You Connect with Experts and Find Resources.',
  description:
    'MyLife has partnered with Debt.com to provide a free service to answer any questions you have about your debt, budget, mortgage, credit and taxes, and find resources to help you get through this crisis.',
  creator: '@debtcom',
  site_name: 'Debt.com',
  url: 'https://helpline.debt.com/my-life',
  fbImg,
  twImg: fbImg,
};

const MyLifePage = () => (
  <Layout
    logos={logos}
    credibilityLogos={credibilityLogos}
    headerPhone={headerPhone}
    heroSection={heroSection}
    infoSection={infoSection}
    helpSection={helpSection}
    disclaimer={disclaimer}
    footer={footer}
    theme={theme}
    seo={seo}
  >
    <SEO
      lang="en"
      url={seo.url}
      twImg={seo.twImg}
      fbImg={seo.fbImg}
      title={seo.title}
      creator={seo.creator}
      description={seo.description}
    />
    <Header showCredibilityLogos />
    <HeroSection />
    <InfoSection />
    <TwoColumns>
      <ATEForm data={form} lang="en" phoneData={realPhone} />
      <HelpSection />
    </TwoColumns>
    <Disclaimer />
    <Footer />
  </Layout>
);

export default MyLifePage;
